import React, { useEffect, useState } from "react";
import FilterModal from "./FilterModal";
import {useDispatch } from "react-redux";
import { getAllProperties } from "../../Store/property/property-action";
import { propertyAction } from "../../Store/property/property-slice";

const Filter = () => {
    //state for controlling modal visuability
    const[isModalopen,setIsmodalopen] =useState (false);
    //state for storing selected filters
    const[selectedFilters,setSelectedFilters]=useState ({});
    const dispatch =useDispatch();
    useEffect(() => {
        dispatch(propertyAction.updateSearchParams(selectedFilters));
        dispatch(getAllProperties());
    },[selectedFilters,dispatch]);
    //function to handle opening the modal/popupwindow
    const handleOpenModal = () => 
    {
        setIsmodalopen(true);
    }
     //function to handle closing the modal
    const handleCloseModal = () => 
    {
        setIsmodalopen(false);
    }
    
    const handleFilterChange = (filterName, value) => 
    {
        //update the selected filter  with the new values
        setSelectedFilters((prevFilters) => ({
            ...prevFilters,
            [filterName]:value,
        })) ;
        
    }
  return (
    <>
    {/* click event to open the modal */}
       <span class="material-symbols-outlined filter" onClick={handleOpenModal}>
tune
</span>
{isModalopen && (
    <FilterModal selectedFilters={selectedFilters}
    onFilterChange={handleFilterChange}
    onClose={handleCloseModal}
    />
)}
    </>
  );
};

export default Filter;