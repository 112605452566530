import {configureStore} from "@reduxjs/toolkit";
import propertySlice from "./property/property-slice";
import propertyDetailsSlice from "./PropertyDetails/propertyDetails-slice";
import userSlice from "./User/user-slice";
import paymentSlice from "./Payment/payment-slice";
import bookingSlice from "./Booking/booking-slice";
import accomodationSlice from "./Accomodation/Accomodation-slice";
const store= configureStore(
    {
        reducer:{

            properties: propertySlice.reducer,
            propertydetails: propertyDetailsSlice.reducer,
            user:userSlice.reducer,
            payment:paymentSlice.reducer,
            booking:bookingSlice.reducer,
            accomodation:accomodationSlice.reducer,
        },
    }
);
export default store;