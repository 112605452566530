import React from "react";


const LoadingSpinner = () => {
  return (
    <div className="text-center" style={{marginTop:'2rem'}}> 
    <div className="spinner-border" role="status">

    </div>
   
    </div>
  );
};

export default LoadingSpinner;