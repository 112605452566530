import React,{useEffect,useState} from "react";

import PropTypes from "prop-types"; //for type-checking props
import "../../CSS/FilterModal.css";
import "react-input-range/lib/css/index.css";
import InputRange from "react-input-range";

const FilterModal = ( {selectedFilters,onFilterChange, onClose}) => {
    const [priceRange,setPriceRange] =useState ({
        min:selectedFilters.priceRange?.min||600,
        max:selectedFilters.priceRange?.max||30000,
    });
    const [propertyType,setPropertyType]
    =useState (
        
       selectedFilters.propertyType || ""
    );
    const [roomType,setRoomType]=useState (
        
        selectedFilters.roomType|| ""
     );
     const [amenities,setAmenities]=useState (
        
        selectedFilters.amenities || []
     );
     useEffect (()=>{
        setPriceRange({
            min:selectedFilters.priceRange?.min||600,
        max:selectedFilters.priceRange?.max||30000,

        })
        setPropertyType(selectedFilters.propertyType || "");
        setRoomType(selectedFilters.roomType || "");
        setAmenities(selectedFilters.amenities || []);
     }, [selectedFilters])
     
     //function to handle change in price range
     const handlePriceRangeChange = (value)=>{
        setPriceRange(value)
     }
     const handleMinInputChange = (e)=>{
        const minValue = parseInt (e.target.value,10);
        setPriceRange((prev) => ({...prev,min: minValue}))
     }
     const handleMaxInputChange = (e)=>{
        const maxValue = parseInt (e.target.value,10);
        setPriceRange((prev) => ({...prev,max: maxValue}))
     }
     //function to handle applying filters
     const handleFilterChange = ()=>{
        onFilterChange("minPrice",priceRange.min);
        onFilterChange("maxPrice",priceRange.max);
        onFilterChange("propertyType",propertyType);
        onFilterChange("roomType",roomType);
        onFilterChange("amenities",amenities);
        onClose();

        
     }
     //option for property types
     const propertyTypeOptions =[
      {
         value:"House", label:"House", icon:"house",
      },
      {
         value:"Flat", label:"Flat", icon:"apartment",
      },
      {
         value:" Guest House", label:" Guest House", icon:"hotel",
      },
      {
         value:" Hotel", label:" Hotel", icon:"meeting_room",
      },
     ];
     //option for room types
     const roomTypeOptions =[
      {
         value:"Entire Room", label:"Entire Room", icon:"hotel",
      },
      {
         value:"Room", label:"Room", icon:"meeting_room",
      },
      {
         value:"Any Type", label:" Any Type", icon:"apartment",
      },
      
     ];

     //option for aminities

     const amenitiesOptions =[
      {
         value:"Wifi", label:"Wifi", icon:"wifi",
      },
      {
         value:"Kitchen", label:"Kitchen", icon:"kitchen",
      },
      {
         value:" Ac", label:" AC", icon:"ac_unit",
      },
      {
         value:"Washing Machine", label:" Washing Machine", icon:"local_laundry_service",
      },
      {
         value:" Tv", label:" Tv", icon:"tv",
      },
      {
         value:"Pool", label:" Pool", icon:"pool",
      },
      {
         value:"Free Parking", label:" Free Parking", icon:"local_parking",
      },
     ];

     //function to handle clearing filter
     const handleClearFilters = () =>{
      setPriceRange({min:600,max:30000});
      setPropertyType ("");
      setRoomType ("");
      setAmenities([]);
     };
     //function to handle aminities changes
     const handleAmenitiesChange= (selectedAmenity)=>{
      setAmenities((prevAmenities)=> prevAmenities.includes (selectedAmenity)? 
       prevAmenities.filter((item) => item !== selectedAmenity)
        :[...prevAmenities,selectedAmenity]);
     };
     //function to handle changes in propety type
     const handlePropertyTypeChange =(selectedType)=>{
      setPropertyType ((prevType) =>
      prevType ===selectedType ? "" : selectedType );
     };
     //function to handle changes in room type
     const handleRoomTypeChange =(selectedType)=>{
      setRoomType ((prevType) =>
      prevType ===selectedType ? "" : selectedType );
     };


  return (
    <div className="modal-backdrop">
     <div className="modal-content">
      <h4>
         Filters <hr/>
      </h4>
      <button className="close-button" onClick={onClose}>
         {/* close the button */}
      <span>&times;</span>
      </button>
     {/* filtersection */}
     <div className="modal-filters-container">
     <div className="filter-section">
      <label>Price range:</label>
      <InputRange 
      minValue={600}
      maxValue={30000}
      value={priceRange}
      onChange={handlePriceRangeChange}
      />
<div className="range-inputs">
<input type="number" value={priceRange.min}
onChange={handleMinInputChange} />

<span>-</span>
<input type="number" value={priceRange.max}
onChange={handleMaxInputChange} />
</div>
</div>

<div className="filter-section">
   <label>Property Type:</label>
   <div className="icon-box">
   
      {propertyTypeOptions.map ((Options) =>(
         <div key={Options.value}
         className={`selectable-box ${
      propertyType === Options.value ? "selected" : ""
     
         }`}
         
         onClick={() => handlePropertyTypeChange(Options.value)}
         
         >
            <span className="material-icons">{Options.icon}</span>
            <span>{Options.label}</span>

</div>
      ))}

         </div>

     </div>
     {/* room type filter */}

     <div className="filter-section">
   <label>Room Type:</label>
   <div className="icon-box">
   
      {roomTypeOptions.map ((Options) =>(
         <div key={Options.value}
         className={`selectable-box ${
      roomType === Options.value ? "selected" : ""
     
         }`}
         
         onClick={() => handleRoomTypeChange(Options.value)}
         
         >
            <span className="material-icons">{Options.icon}</span>
            <span>{Options.label}</span>

</div>
      ))}

         </div>

     </div>

     {/* aminities for filter */}
     
     <div className="filter-section">
   <label>Amenities</label>
   <div className="amenities-checkboxes">
   
      {amenitiesOptions.map ((Option) =>(
         <div key={Option.value}
         className="amenity-checkbox">
         {
            console.log(amenities.includes (Option.value))}
         <input
         type="checkbox"
         value={Option.value}
         checked ={amenities.includes (Option.value)}
         onChange={() => handleAmenitiesChange(Option.value)}
         />
            <span className="material-icons amenitieslabel">{Option.icon}</span>
            <span>{Option.label}</span>

</div>
      ))}

         </div>

     </div>

     {/* filter action for buttons */}
     <div className="filter-buttons">
      <button className="clear-button" onClick={handleClearFilters}>
         {""}
         Clear
      </button>
     <button onClick={handleFilterChange}>Apply Filters</button>
     </div>

     </div>
     </div>
    </div>
  );
};

FilterModal.propTypes = {

   selectedFilters:PropTypes.object.isRequired,
   onFilterChange:PropTypes.func.isRequired,
   onClose: PropTypes.func.isRequired,
}
export default FilterModal;